
const southhouse = require('./assets/southhouse.png');
const kitchenstep = require('./assets/kitchenstep/main_image_kitchen_mobile.png');
const franklinsocial = require('./assets/franklinsocial/main_image.png')
const rumba = require('./assets/RumbaCubana.png');
const hudsonCo = require('./assets/HudsonCo.png');
const mathews = require('./assets/Mathews.png');

export const config = {  

    encryption_key: "asdfasdfsdf",
    validation_key: "asdfasdfsdf",
    apiURL: "https://jcsocialapi.onrender.com/api",
    //apiURL: "https://api.diningsocialnj.com/api",
    //apiURL: "http://localhost:3000/api",
    cities: [
      { name: 'Jersey City', color: '#35a190'},
      { name: 'Hoboken', color: '#974d4d'},
      { name: 'Newport', color: '#35a139'},
      { name: 'Newark', color: '#a19935'},
      { name: 'Other', color: '#a13597'},
    ],
    places: [{
      type: 'restaurant', city: 'Jersey City',
      name: "Frankie",
      link: "https://goo.gl/maps/kbPEENyV2AdrUpH26",
      id: "08f119cf-25c9-485c-8ca3-98fed76170ce"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Better Days",
      link: "https://maps.app.goo.gl/pUCWaaDnebfSRd439",
      id: "8fdb71e3-ec8b-4398-8478-4b3d69b52bb8"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Rumba Cubana",
      link: "https://goo.gl/maps/rsmiTd6kAHA7eKAp8",
      id: "78b6bd95-b3fe-47e0-9d45-b63b14539815"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Luna",
      link: "https://goo.gl/maps/gEHEQTHEFB46oBWZ9",
      id: "4b04e710-f80c-4683-b5f4-949dadb1df46"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Jane Doe",
      link: "https://goo.gl/maps/ep68uNMm3zyfyixu5",
      id: "3d2a1a10-68e5-43f3-809b-dbfd37b54939"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Light Horse Tavern",
      link: "https://goo.gl/maps/VNwLGDTZGNMeLGXz8",
      id: "b64fb5d2-f71e-4bdd-8490-2c1e5b8fc2d8"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Mathews Food and Drink",
      link: "https://goo.gl/maps/v2gSWZr5wtcmc8pg8",
      id: "5d46268e-5e56-42a5-9b56-fc4077c0332b"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Lokal",
      link: "https://maps.app.goo.gl/s7FwkpgdCgeoeKbe8",
      id: "e4f83a7a-4e66-4523-b51f-d4358560a0ec"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Skinner's Loft",
      link: "https://maps.app.goo.gl/GqVTmsruJXhXH29p8",
      id: "1cb8181e-0c4d-4aff-b8b1-83714a30f7a4"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Edward's Steakhouse",
      link: "https://maps.app.goo.gl/cUE9dX16bfB96WMw6",
      id: "40950994-ba20-4a4f-aabc-832c7643ab68"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Roman Nose",
      link: "https://maps.app.goo.gl/hSzxwvS5oYE5kvSg8",
      id: "8d65d62c-5bdb-4984-be62-5e54a7de877e"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Zeppelin Hall",
      link: "https://goo.gl/maps/sG6xHCSa8edkVGwE9",
      id: "90d821b9-41ba-4f03-ac10-dfbc0ea5210a"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Battello",
      link: "https://goo.gl/maps/VnLifYjQ92DGvju57",
      id: "6a1cdabc-f7f6-4963-8d0b-fd95fbb0a3ae"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Madame",
      link: "https://maps.app.goo.gl/rFtufnjJfcrJAQUf6",
      id: "9ed87d89-4e8e-429b-aa1a-2918acc850ea"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Corto",
      link: "https://maps.app.goo.gl/7eHWD9vWZUh3454GA",
      id: "162c6aa4-1e42-4921-80b6-adf2f486fb2b"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "ITA Italian Kitchen",
      link: "https://maps.app.goo.gl/EcyL2GztuqCC8FtX9",
      id: "ccce0561-2165-4a93-9aa5-4b482c504300"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "South House",
      link: "https://goo.gl/maps/KGov9R7BMkTjChh97",
      id: "b6effeb0-67c7-4dc9-b60b-7b25734d45de"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Chickie's",
      link: "https://maps.app.goo.gl/aaYgcepj3rpXqiXs8",
      id: "3793d187-0fa8-405d-b516-66ef99fcf6f4"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Hamilton Inn",
      link: "https://maps.app.goo.gl/aaYgcepj3rpXqiXs8",
      id: "59be6995-cea1-436a-a8be-ca52c34f8a8e"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Dream Vista",
      link: "https://goo.gl/maps/4MRAe8LqYBrUyDE39",
      id: "985b8107-e91f-43da-9efd-03b624da40f3"
    },
    {
      type: 'restaurant', city: 'Jersey City',
      name: "Choc o Pain, JSQ",
      link: "https://g.co/kgs/nWY1qFS",
      id: "3be1a76a-b36a-4210-b268-c10b9cc276ac"
    },
    {
      "type": "cafe", city: 'Jersey City',
      "link": "https://g.co/kgs/wN9Wuk7",
      "name": "O Kafe",
      id: 'a074ec06-fdc6-4fce-ad98-32346ce06980'
    },
    {
      "type": "cafe", city: 'Jersey City',
      "link": "https://g.co/kgs/G9urE39",
      "name": "Cafe Madeline",
      id: '655c09f3-cb80-492e-802d-9e9e3cdf3e6b'
    },
    {
      "type": "cafe", city: 'Jersey City',
      "link": "https://g.co/kgs/NxXo88B",
      "name": "Choc O Pain (JC Downtown)",
      id: '4fba6f4c-e23b-4601-bdb3-9f7b841e5fba'
    },
    {
      "type": "cafe", city: 'Jersey City',
      "link": "https://g.co/kgs/7wxnsQR",
      "name": "Wattle Cafe",
      id: 'd7f2bfec-6da9-4596-b9b9-cfe810d88116'
    }
    ],
    stripe_price_basic_member_test: "price_1OtDZLF4ANwwDrzGOUn2cn68",
    stripe_price_basic_member_live: "price_1OtDadF4ANwwDrzGvQTSjoc7",
    type_drop: [
      { name: "Choose a type of event", value: "-1" },
      { name: "Coffee", value: "1", placeholder_image: 'https://diningsocial-assets.s3.amazonaws.com/coffee.png' },
      { name: "Drink", value: "2", placeholder_image: 'https://diningsocial-assets.s3.amazonaws.com/hh.png' },
      { name: "Non-Hosted Dinner", value: "3", placeholder_image: 'https://diningsocial-assets.s3.amazonaws.com/dinner.png' }
    ],
    what_drop: [
      { name: "Choose a theme", value: "-1" },
      { name: "New Friends", value: "1" },
      { name: "New Girl Friends", value: "2" },
      { name: "New Guy Friends", value: "3" },
      { name: "Request a New Title", value: "-2" }
    ],
    restaurant_config: {
       /*  "1": {
          restaurant_name: "Franklin Social",
          dinner_time: "6:00 pm",
          link: "https://franklinsocial.com",
          main_image: franklinsocial,
          address: '292 Barrow St, Jersey City, NJ 07302',
          directions_link: 'https://goo.gl/maps/zqLJ7xx8cDaYEdjX8',
          background_color: '#ffefd1',
          secondary_background_color: 'black',
          button_color: '#ffefd1'
        }, */
        "1": {
          restaurant_name: "Rumba Cubana",
          dinner_time: "6:00 pm",
          link: "http://www.rumbacubana.com/jerseycity/",
          main_image: rumba,
          address: '235 Pavonia Ave, Jersey City, NJ 07302',
          directions_link: 'https://goo.gl/maps/rsmiTd6kAHA7eKAp8',
          background_color: '#ffefd1',
          secondary_background_color: 'black',
          button_color: '#ffefd1'
        },
        "2": {
          restaurant_name: "Mathews Food and Drink",
          dinner_time: "6:00 pm",
          link: "https://www.mathewsfoodanddrink.com",
          main_image: mathews,
          address: '351 Grove St, Jersey City, NJ 07302',
          directions_link: 'https://goo.gl/maps/v2gSWZr5wtcmc8pg8',
          background_color: '#8cab3c',
          secondary_background_color: '#8cab3c',
          button_color: 'white'
        },
       /*  "2": {
          restaurant_name: "The Kitchen Step",
          dinner_time: "6:00 pm",
          link: "https://www.kitchenstepjc.com",
          main_image: kitchenstep,
          address: '500 Jersey Ave, Jersey City, NJ 07302',
          directions_link: 'https://goo.gl/maps/34EL1DezhvY8eyy18',
          background_color: '#8cab3c',
          secondary_background_color: '#8cab3c',
          button_color: 'white'
        }, */
        "3": {
          restaurant_name: "Hudson & Co",
          dinner_time: "6:30 pm",
          link: "https://www.hudsonandconj.com/",
          main_image: hudsonCo,
          address: '3 2nd St, Jersey City, NJ 07302',
          directions_link: 'https://goo.gl/maps/61jtZApHfjLfSrHT6',
          background_color: '#eee6d9',
          secondary_background_color: '#803e0e',
          button_color: 'black'
        } 
        /* "3": {
          restaurant_name: "South House",
          dinner_time: "6:30 pm",
          link: "https://www.southhousejc.com",
          main_image: southhouse,
          address: '149 Newark Ave, Jersey City, NJ 07302',
          directions_link: 'https://goo.gl/maps/KGov9R7BMkTjChh97',
          background_color: '#eee6d9',
          secondary_background_color: '#803e0e',
          button_color: 'black'
        } */
      },
    calendar: {
      "7/10/2023": [
        {
          id: "eab9f932-2c75-4a86-b843-4a8ff90108d6",
          name: "Meet New Friends 20s/30s Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          id: "207ed1de-98a7-4597-a89a-d9e63fe12c92",
          name: "Meet New Friends 40s+ Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },

        {
          id: "c702246e-1382-4d81-9252-0b9c8e17f5b9",
          name: "Meet New Friends 40s+ Dinner - Hosted",
          time: 18.5,
          place_id: "9e526ed1-3e47-4e0c-83c8-6f1fa17c7f64",
          hosted: true
        },
        {
          id: "5f56d1af-74a3-439e-a8a5-97ff5d49154d",
          name: "Meet New Friends 20s/30s Dinner",
          time: 19,
          place_id: "e1f26ebd-8ac7-4c7a-9bdc-9fca6e8d95f3",
          hosted: false
        },
      ],
      "7/11/2023": [
        {
          id: "4821d2b4-6f2f-4e8b-8a4c-8c3d1e4f086e",
          name: "Meet New Friends 20s/30s Coffee",
          time: 12,
          place: "Dream Vista",
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          id: "e0dc4c8e-9a51-4b4f-b3b9-67954632e39a",
          name: "Meet New Friends 40s+ Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          id: "a5d5e393-6fc2-4f7d-a45d-0a45a372dd72",
          name: "Meet New Friends 20s/30s Dinner",
          time: 19,
          place_id: "c0242d51-84f4-4b8b-9816-3c40992b3786",
          hosted: false
        },
        {
          id: "9c3e8e96-3282-4e39-b3d3-22c2f18d1a8b",
          name: "Meet New Friends 40s+ Dinner - Hosted",
          time: 18.5,
          place_id: "691b45e2-9e0b-4b24-8de0-8af7f6480a4d",
          hosted: true
        }
      ],
      "7/12/2023": [
        {
          name: "Meet New Friends 20s/30s Coffee",
          time: 12,
          place: "Dream Vista",
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          canceled: true,
          cancel_reason: "We decided to cancel today's coffee due to inclement weather.",
          hosted: false
        },
        {
          name: "Meet New Friends 40s+ Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          name: "Meet New Friends 20s/30s Dinner",
          time: 19,
          place_id: "e9763949-24c9-409d-bf7f-4461fe9eb5ec",
          hosted: false
        },
        {
          name: "Meet New Friends 40s+ Dinner - Hosted",
          time: 18.5,
          place_id: "5d46268e-5e56-42a5-9b56-fc4077c0332b",
          hosted: true
        },
      ],
      "7/13/2023": [
        {
          name: "Meet New Friends 20s/30s Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          name: "Meet New Friends 40s+ Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          name: "Meet New Friends 20s/30s Dinner",
          time: 19,
          place_id: "e9763949-24c9-409d-bf7f-4461fe9eb5ec",
          hosted: false
        },
        {
          name: "40s+ Dinner - Non-hosted",
          time: 19,
          place_id: "5d46268e-5e56-42a5-9b56-fc4077c0332b",
          hosted: false
        },
      ],
      "7/14/2023": [
        {
          name: "Meet New Friends 20s/30s Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          name: "Meet New Friends 40s+ Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          name: "Meet New Friends 20s/30s Dinner",
          time: 19,
          place_id: "e9763949-24c9-409d-bf7f-4461fe9eb5ec",
          hosted: false
        },
        {
          name: "40s+ Dinner - Non-hosted",
          time: 19,
          place_id: "5d46268e-5e56-42a5-9b56-fc4077c0332b",
          hosted: false
        },
      ],
      "7/15/2023": [
        {
          name: "Meet New Friends 20s/30s Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          name: "Meet New Friends 40s+ Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          name: "Meet New Friends 20s/30s Dinner",
          time: 19,
          place_id: "e9763949-24c9-409d-bf7f-4461fe9eb5ec",
          hosted: false
        },
        {
          name: "40s+ Dinner - Non-hosted",
          time: 19,
          place_id: "5d46268e-5e56-42a5-9b56-fc4077c0332b",
          hosted: false
        },
      ],
      "7/16/2023": [
        {
          name: "Meet New Friends 20s/30s Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          name: "Meet New Friends 40s+ Coffee",
          time: 12,
          place_id: "985b8107-e91f-43da-9efd-03b624da40f3",
          hosted: false
        },
        {
          name: "Meet New Friends 20s/30s Dinner",
          time: 19,
          place_id: "e9763949-24c9-409d-bf7f-4461fe9eb5ec",
          hosted: false
        },
        {
          name: "40s+ Dinner - Non-hosted",
          time: 19,
          place_id: "5d46268e-5e56-42a5-9b56-fc4077c0332b",
          hosted: false
        },
      ]
    },
    not_choosable: ['6/27/2023', '7/10/2023', '7/12/2023'],
    holidays: ['7/4/2023', '7/5/2023'],
    host_profile: {
        "name": "⭐️ Host ⭐️",
        "age": "40ish 😅",
        "profile": {
          "job": "Hosting Fabulous Dinners | Software Engineer",
          "industries": "Any and All",
          "professions": "Any and All",
          "hobbies": "Meeting New People, Bird Watching, Painting, Eating, Drinking, Playing Pool, Nintendo, Singing, Hiking, Thinking, Looking for Fossils in Nature, Dogs, Goats, Thinking of Getting a Kayak"
        },
        "group": 1
    }
}



