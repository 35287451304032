import React, { Component, useRef } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, Dimensions, TouchableOpacity, Text, Image, ScrollView, Animated, Easing, Touchable, Picker } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { useNavigate, useParams } from 'react-router-dom';
import { Calendar, Event, DemoEvents, Modal } from '../../containers';
import { Button, Input, Link } from '../../components';
import { style } from './style';
import Carousel from 'react-native-reanimated-carousel';
import { Analytics, PageHit } from 'expo-analytics';
import { Video } from 'expo-av';
//const analytics = new Analytics('G-YCC5QPC8BJ');
//const analytics = new Analytics.Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';
import { config } from '../../../config';
import { global_style } from '../../../global_style';
import AuthController from '../../controllers/authController';
import Icon from 'react-native-vector-icons/FontAwesome';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';
import Utilities from '../../utilities';
import UserController from '../../controllers/userController';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import AdminController from '../../controllers/adminController';
import axios from 'axios';

let window_width = Dimensions.get('window').width;
let window_height = Dimensions.get('window').height;

class Dining_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page_index: 0,
      display_section: 'phone_input',
      phone_number: '',
      phone_number_valid: false,
      validation_code: '',
      email: '',
      password: '',
      tos_accepted: false,
      date: props.date,
      animatedValue: new Animated.Value(0),
      isLoggedIn: this.props.isLoggedIn,
      currentIndex: 0,
      currentIndexM: 0,
      currentIndexN: 0,
      currentIndexT: 0,
      next_event_dining_photos: [
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/halifax_one.png', order: 1},

        {uri: 'https://diningsocial-assets.s3.amazonaws.com/IMG_4615.MOV', type: 'movie', order: 2},
        /* {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_3.png', order: 1}, */

        //https://diningsocial-assets.s3.amazonaws.com/halifax_four.png
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/halifax_five.png', order: 3},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/IMG_4610.MOV', type: 'movie', order: 4},  
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/halifax_six.png', order: 5},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/IMG_4611.MOV', type: 'movie', order: 6},        
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/halifax_four.png', order: 7},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/halifax_seven.png', order: 8},
      ],
      taco_hat_photos: [
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/t_5.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/t_4.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/t_1.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/t_2.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/t_6.png', order: 1},
      ],
      event_dining_photos: [
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.png', order: 1}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_two.MOV', type: 'movie', order: 0}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.mov', type: 'movie', order: 2}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_two.png', order: 3}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_three.JPG', order: 4},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_last.JPG', order: 6}],
      member_dining_photos: [
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_eight.png', order: 8},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seven.jpg', order: 0},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_one.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_twelve.png', order: 2},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_thirteen.png', order: 2.5},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_three.png', order: 3},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_five.png', order: 6},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_ten.png', order: 10},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/m_eleven.png', order: 11},
      ]
    }

    this.translateX = new Animated.Value(0);
    this.translateXM = new Animated.Value(0);
    this.translateXN = new Animated.Value(0);
    this.translateXT = new Animated.Value(0);

    this.image_array = [
      require('../../../assets/IMG_1323.jpg'),
      require('../../../assets/franklinsocial/IMG_1479.jpg'),
      
      require('../../../assets/IMG_1324.jpg'),
      require('../../../assets/franklinsocial/IMG_1482.jpg'),

      require('../../../assets/franklinsocial/IMG_1483.jpg'),
      require('../../../assets/IMG_1313.jpg'),

      require('../../../assets/franklinsocial/IMG_1493.jpg'),
      require('../../../assets/IMG_1656.jpeg'), 
    ];

    let images_top = require.context('../../../assets/home_carousel/top', true);
    let images_bottom = require.context('../../../assets/home_carousel/bottom', true);

    this.image_array_top = images_top.keys().map(image => images_top(image));
    this.image_array_bottom = images_bottom.keys().map(image => images_bottom(image));

    this.zep_vid_ref = React.createRef();
    this.scrollViewRef = React.createRef();
    this.calendarRef = React.createRef();
    this.hostedRef = React.createRef();
    this.calendarModule = React.createRef();
    this.demo_events_ref = React.createRef();
    this.modal_ref = React.createRef();
    this.waitlist_ref = React.createRef();
    this.dining = React.createRef();
    this.principles = React.createRef();
    this.elementRef = React.createRef();

    this.showDay = this.showDay.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.addNewsletter = this.addNewsletter.bind(this);
    this.addCoffee = this.addCoffee.bind(this);
    this.emailRegister = this.emailRegister.bind(this);

    this.viewHosted = this.viewHosted.bind(this);
    this.viewFree = this.viewFree.bind(this);
    this.viewJCRF = this.viewJCRF.bind(this);
    this.viewMeetUps = this.viewMeetUps.bind(this);
    this.scrollCarousel = this.scrollCarousel.bind(this);
    this.carouselPrev = this.carouselPrev.bind(this);
    this.carouselNext = this.carouselNext.bind(this);

    this.signUp = this.signUp.bind(this);

    this.goTo = this.goTo.bind(this);

    this.clickDownloadButton = this.clickDownloadButton.bind(this);

    this.signupWaitlist = this.signupWaitlist.bind(this);
    this.openWaitlistModal = this.openWaitlistModal.bind(this);

    this.checkVideoScroll = this.checkVideoScroll.bind(this);

    this.referFriend = this.referFriend.bind(this);
    this.sendContact = this.sendContact.bind(this);
  }

  async componentDidMount() {

    !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
    rdt('init','a2_dycdi2h3creo', {"aaid":"<AAID-HERE>","email":"<EMAIL-HERE>","externalId":"<EXTERNAL-ID-HERE>","idfa":"<IDFA-HERE>"});rdt('track', 'PageVisit');

    let images = {
      "top": [
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/eight.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/eleven.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/fifteen.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/five.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/four.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/fourteen.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/nine.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/one.JPG",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/seven.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/seventeen.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/six.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/sixteen.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/ten.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/thirteen.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/three.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/twelve.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/top/two.png"
      ],
      "bottom": [
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/eight.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/eleven.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/fifteen.png",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/five.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/four.JPG",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/fourteen.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/nine.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/one.JPEG",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seven.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seventeen.png",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/six.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/sixteen.png",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/ten.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/thirteen.png",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/three.JPG",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/twelve.jpg",
          "https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/two.JPG"
      ]
    }

    if(images.success) {
      this.image_array_top = images.top;
      this.image_array_bottom = images.bottom;
    }

    let center_image = require('../../../assets/logo.png');

    this.animatedLeftMargin = new Animated.Value(0)

    const analytics = new Analytics('G-YCC5QPC8BJ');
    analytics.hit(new PageHit('Dining'))
      .then(() => console.log("success"))
      .catch(e => console.log(e.message));

    if(this.props.link) {
      track(`${this.props.link} - Dining Page`);
    } else {
      track('Dining Page');
    }

    let shouldPlay = await localStorage.getItem('shouldPlay');
    shouldPlay = !shouldPlay;

    console.log('shouldPlay', shouldPlay)

    let rsvps;
    let event_id = '567d059b-33e3-496f-a995-f2373e5e6319';
    ({rsvps} = await UserController.getDateRsvpsForEvent({event_id}));

    this.setState({
      rsvps,
      mounted: true,
      shouldPlay
    }, async ()=> {

      setTimeout(()=>{    let window_width = Dimensions.get('window').width;
        let is_mobile = window_width < 500;

  this.modal_ref.setContent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center', borderWidth: 20, borderColor: '#fdcd37'}}>
    <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 40, marginTop: is_mobile ? 80 : 0, fontFamily: 'Ysabeau', fontSize: is_mobile ? 30 : 30, textAlign: 'center', marginTop: 50}}>DiningSocial is now Bunch 🍓!</Text>
    <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: is_mobile ? 80 : 80, fontFamily: 'Comfortaa', fontSize: is_mobile ? 18 : 20, textAlign: 'center', marginBottom: 5}}>We've had such luck putting on 100+ dinners and brunches over the past 2 years that we built an app for our New Jersey Community.</Text>
    <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: is_mobile ? 30 : 30, fontFamily: 'Comfortaa', fontSize: is_mobile ? 18 : 20, textAlign: 'center', marginBottom: 5}}>Join Us!</Text>

      <View style={{marginTop: is_mobile ? 20 : 30, marginBottom: 20, alignSelf: 'center', textAlign: 'center'}}>
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 30, color: 'black'}}>Go to Bunch 🍓</Text>}
                loading={false}
                style={{
                  backgroundColor: '#40E0D0',
                  borderRadius: 3,
                  paddingTop: 20,
                  paddingBottom: 27,
                  width: 300,
                  paddingHorizontal: 5, marginBottom: 10}}
                onPress={()=>{window.location.replace('https://www.letsbunch.com')}} />
      </View>
    </View>, is_mobile ? window_width : 700, '100vh')}, 1000)

      await localStorage.setItem('shouldPlay', 'true');
      if(this.state.date) {
        this.showDay();
      }
      
      let window_width = Dimensions.get('window').width;

      let par = window_width < 700 ? 200 : 300

      Animated.loop(
        Animated.sequence([
          Animated.timing(this.animatedLeftMargin, {
            toValue: - (par * this.image_array_bottom.length),
            duration: window_width < 700 ? 50000 : 70000,
          }),
          Animated.timing(this.animatedLeftMargin, {
            toValue: 0,
            duration: window_width < 700 ? 50000 : 70000
          })
        ]),
        {
          iterations: 4
        }
      ).start();

      this.checkVideoScroll()
    })
  }

  checkVideoScroll() {
    const windowHeight = Dimensions.get('window').height;

    const that = this;

    console.log('getting here')
   
    
    const targetElement = document.getElementById('effortElement');

    function isElementInView(el) {
      const rect = el.getBoundingClientRect();
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
      const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= windowHeight &&
        rect.right <= windowWidth
      );
    }

    function checkVisibility() {
      if (isElementInView(targetElement)) {
        console.log('Target element is in view');
        that.setState({effortVisible: true, networkingVisible: true}, ()=>{
          setTimeout(()=>{
            console.log('setting state networking')
            that.setState({networkingVisible: true})
          }, 3000)
        })
      } else {
        console.log('Target element is out of view');
      }
    }

    window.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);

    // Initial check
    checkVisibility();
  }

  goTo(link) {
    window.open(link)
  }

  updateEmail(email, name) {

    let email_valid = !!this.state[`email_${name}`];

    let valid = Utilities.validateEmail(email);

    email_valid = email_valid && !!email && valid;

    this.setState({
      [`email_valid_${name}`]: email_valid,
      [`email_${name}`]: email
    })
  }

  async addNewsletter() {

    this.setState({newsletter_loading: true}, async ()=>{
      let email = this.state.email_newsletter;

      await AuthController.addNewsletter({email});

      this.setState({
        newsletter_loading: false,
        email_newsletter: null,
        email_valid_newsletter: null,
        newsletter_added: true
      });
    })
  }

  async signupWaitlist(done) {

    this.setState({waitlist_loading: true}, async ()=>{
      let email = this.state.email_waitlist;

      let { hear, hear_text, people_meet } = this.state;

      let data = {
        hear, hear_text, people_meet
      }

      await AuthController.addWaitlist({email, data});

      this.setState({
        waitlist_loading: false,
        email_valid_newsletter: null,
      }, ()=>{
        if(done) {
         
          let window_width = Dimensions.get('window').width;
          let is_mobile = window_width < 500;
          this.modal_ref.setContent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: is_mobile ? 80 : 0, fontFamily: 'Comfortaa', fontSize: is_mobile ? 30 : 20, textAlign: 'center', marginBottom: 5}}>Thank you! </Text>
      <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: is_mobile ? 80 : 0, fontFamily: 'Comfortaa', fontSize: is_mobile ? 30 : 20, textAlign: 'center', marginBottom: 5}}>We'll be in touch.</Text>

      <Text style={{fontFamily: 'Ysabeau', fontSize: 20, marginTop: 60, marginBottom: 5}}>Till then, Toodles!</Text>

        <View style={{marginTop: is_mobile ? 20 : 20, alignSelf: 'center', textAlign: 'center'}}>
            <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>See you soon.</Text>}
                  loading={false}
                  style={{
                    backgroundColor: '#e32652',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 200,
                    paddingHorizontal: 5, marginBottom: 10}}
                  onPress={this.modal_ref.close} />
        </View>
      </View>, is_mobile ? window_width : 500, '100vh')
        } else {
          this.openWaitlistModal()
        }
      });
    })
  }

  async addCoffee() {
    let email = this.state.email_newsletter;

    email = encodeURIComponent(email);

    await AuthController.addNewsletter({email, coffee: true});

    this.props.navigate(`/register?email=${email}&coffee=true`);
  }

  async emailRegister() {
    let email = this.state.email_register;

    email = encodeURIComponent(email);

    this.props.navigate(`/register?email=${email}`);
  }

  showDay() {

    if(this.calendarRef) {
      this.calendarRef.scrollIntoView();
    }

    this.setState({
      selected_date: new Date('8/10/2023')
    });
  }

  viewMeetUps() {
    track('View Group meetups dinner')
/*     if(this.calendarModule && this.calendarModule.current) {
      this.calendarModule.current.filterUserEvents(true);
    } */

    if(this.calendarRef) {
      this.calendarRef.scrollIntoView();
    }
  }

  viewHosted() {
    track('View Group meetups coffee')
    if(this.calendarModule && this.calendarModule.current) {
      this.calendarModule.current.filterHostedEvents(true);
    }

    if(this.calendarRef) {
      this.calendarRef.scrollIntoView();
    }
  }

  viewJCRF() {
    if(this.calendarModule && this.calendarModule.current) {
      this.calendarModule.current.filterJCRFEvents(true);
    }

    if(this.calendarRef) {
      this.calendarRef.scrollIntoView();
    }
  }

  viewFree() {
    track('View free meetups')
    /* if(this.calendarModule && this.calendarModule.current) {
      this.calendarModule.current.filterFreeEvents(true);
    } */

    if(this.hostedRef) {
      this.hostedRef.scrollIntoView();
    }
  }

  scrollCarousel(index) {
    track('Carousel Member Callouts ' + index);
    this.calendar_ref.scrollTo({index, animated: true})

    this.setState({
      page_index: index
    })
  }

  carouselPrev() {
    track('Carousel 1 - prev');
    let {page_index} = this.state;
    page_index = page_index === 0 ? 3 : --page_index;

    this.calendar_ref.scrollTo({index: page_index, animated: true})

    this.setState({
      page_index
    })
  }

  carouselNext() {
    track('Carousel 1 - next');
    let { page_index } = this.state;
    console.log('page_index' ,page_index)
    page_index = page_index === 3 ? 0 : ++page_index;
    console.log('page_index' ,page_index)

    this.calendar_ref.scrollTo({index: page_index, animated: true})

    this.setState({
      page_index
    })
  }

  signUp(num) {
    track(`Sign Up From Events Explanation Buttons ${num}`);

    this.props.navigate('/register')

  }

  clickDownloadButton() {
    let window_width = Dimensions.get('window').width;
      let is_mobile = window_width < 500;

      let text_size = is_mobile ? 24 : 16;
  
      this.modal_ref.setContent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: is_mobile ? 80 : 20, fontFamily: 'Comfortaa', fontSize: is_mobile ? 30 : 20, textAlign: 'center', marginBottom: 5}}>Save DiningSocial as an icon on your phone's Home Screen</Text>

      <View style={{padding: 40}}>
        <Text style={{fontFamily: 'Ysabeau', textAlign: 'left', fontSize: text_size, justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>1. Select the share <Image style={{width: 30, height: 50, marginBottom: -17}} resizeMode={'contain'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/share_icon.png'}} /> button in the footer of this browser window.</Text>
        <View style={{paddingVertical: 30}}><Image style={{width: is_mobile ? '81vw' : 300, height: 100}} resizeMode={'contain'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/share_footer.png'}} /></View>
        <Text style={{fontFamily: 'Ysabeau', fontSize: text_size, textAlign: 'left'}}>2. Scroll down to the 'Add to Home Screen' option and select it.</Text>
        <Text style={{fontFamily: 'Ysabeau', fontSize: text_size, textAlign: 'left', marginTop: is_mobile ? 30 : 0}}>3. You can now access DiningSocialNJ.com like an app.</Text>
      </View>

        <View style={{marginTop: is_mobile ? 20 : 0, alignSelf: 'center', textAlign: 'center'}}>
            <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Done</Text>}
                  loading={false}
                  style={{
                    backgroundColor: '#e32652',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 200,
                    paddingHorizontal: 5, marginBottom: 10}}
                  onPress={this.modal_ref.close} />
        </View>
      </View>, is_mobile ? window_width : 500, '100vh', null)
  }

  openWaitlistModal() {
    let { hear, hear_text, people_meet } = this.state;
    people_meet = people_meet ? people_meet : {};
    let window_width = Dimensions.get('window').width;
      let is_mobile = window_width < 500;

      let text_size = is_mobile ? 24 : 16;
  
      this.modal_ref.setContent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={{paddingHorizontal: 20, lineHeight: is_mobile ? 36 : 26, marginTop: is_mobile ? 80 : 0, fontFamily: 'Comfortaa', fontSize: is_mobile ? 30 : 20, textAlign: 'center', marginBottom: 5}}>Some things that would help us improve...</Text>

      <Text style={{fontFamily: 'Ysabeau', fontSize: 20, marginTop: 20, marginBottom: 5}}>How Did You Hear About Us?</Text>
              {/* <Text style={style.label_explainer}>The name field will mostly be used for the host to identify you.</Text> */}
              <View style={[style.a_row, {width: 300, alignSelf: 'center', flexDirection: 'column'}]}>
                  <View style={style.age_wrapper}>
                    <Picker
                        selectedValue={hear}
                        mode={'dialog'}
                        style={style.drop_style_profile}
                        onValueChange={(hear)=>this.setState({hear}, this.openWaitlistModal)}
                      >
                        {['Choose an option...', 'Instagram', 'Facebook', 'Reddit', 'Google', 'Hoboken Girl', 'Other'].map(v => {
                          return <Picker.Item
                            fontSize={16}
                            style={{ fontSize: 16 }}
                            key={v}
                            label={v}
                            value={v}
                          />
                        })}
                      </Picker>
                  </View>
                  
                    {hear === 'Other' ? 
                    <View style={{marginTop: 10}}>
                      <Input style={style.input_box} type="text" value={hear_text} placeholder="How did you hear about us?" onChangeText={event => { this.setState({hear_text: event}, this.openWaitlistModal) }} />
                    </View>
                    : null}

                  
              </View>

              <Text style={{fontFamily: 'Ysabeau', fontSize: 20, marginTop: 20, marginBottom: 5}}>At each dinner/coffee, I'd like to meet..</Text>

              <View>
              {['People I do not know', `Mix of people I know and don't know`, 'Only my gender', 'Mixed genders', 'Only my age range', 'Mix of age ranges' ].map(v => {
                    return <Input checked={people_meet[v]} onCheck={()=>{
                        let { people_meet } = this.state;

                        people_meet = people_meet ? people_meet : {}

                        this.setState({
                        people_meet: {
                          ...people_meet,
                          [v]: !people_meet[v]
                        }
                      }, this.openWaitlistModal)}}
                      check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                        flexWrap: 'wrap',
                          overflowWrap: 'anywhere',
                          textWrap: 'wrap',
                          marginLeft: 10,
                          fontFamily: 'Ysabeau',
                          fontSize: 16,
                          color: 'black'}}}
                      type="check" label={<Text style={global_style.normal_text_dark_medium}>{v}</Text>} />
                  })}
                
              </View>


        <View style={{marginTop: is_mobile ? 20 : 20, alignSelf: 'center', textAlign: 'center'}}>
            <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Done</Text>}
                  loading={false}
                  style={{
                    backgroundColor: '#e32652',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 200,
                    paddingHorizontal: 5, marginBottom: 10}}
                  onPress={()=>this.signupWaitlist(true)} />
        </View>
      </View>, is_mobile ? window_width : 500, '100vh', null, null, ()=>this.signupWaitlist(true))
  }

  handleNextPress = (width) => {
    const { currentIndex, event_dining_photos } = this.state;

    const nextIndex = currentIndex + 1 < event_dining_photos.length ? currentIndex + 1 : 0;


    console.log('nextIndex', nextIndex)

    Animated.timing(this.translateX, {
      toValue: -width * nextIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndex: nextIndex });
  };

  handlePrevPress = (width) => {
    const { currentIndex, event_dining_photos } = this.state;

    const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : event_dining_photos.length - 1;
    Animated.timing(this.translateX, {
      toValue: -width * prevIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndex: prevIndex });
  };

  handleNextPressM = (width) => {
    const { currentIndexM, member_dining_photos } = this.state;

    const nextIndex = currentIndexM + 1 < member_dining_photos.length ? currentIndexM + 1 : 0;


    console.log('nextIndex', nextIndex)

    Animated.timing(this.translateXM, {
      toValue: -width * nextIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndexM: nextIndex });
  };

  handlePrevPressM = (width) => {
    const { currentIndexM, member_dining_photos } = this.state;

    const prevIndex = currentIndexM - 1 >= 0 ? currentIndexM - 1 : member_dining_photos.length - 1;
    Animated.timing(this.translateXM, {
      toValue: -width * prevIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndexM: prevIndex });
  };

  handleNextPressN = (width) => {
    const { currentIndexN, next_event_dining_photos } = this.state;

    const nextIndex = currentIndexN + 1 < next_event_dining_photos.length ? currentIndexN + 1 : 0;


    console.log('nextIndex', nextIndex)

    Animated.timing(this.translateXN, {
      toValue: -width * nextIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndexN: nextIndex });
  };

  handlePrevPressN = (width) => {
    const { currentIndexN, next_event_dining_photos } = this.state;

    const prevIndex = currentIndexN - 1 >= 0 ? currentIndexN - 1 : next_event_dining_photos.length - 1;
    Animated.timing(this.translateXN, {
      toValue: -width * prevIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndexN: prevIndex });
  };

  handleNextPressT = (width) => {
    const { currentIndexT, taco_hat_photos } = this.state;

    const nextIndex = currentIndexT + 1 < taco_hat_photos.length ? currentIndexT + 1 : 0;


    console.log('nextIndex', nextIndex)

    Animated.timing(this.translateXT, {
      toValue: -width * nextIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndexT: nextIndex });
  };

  handlePrevPressT = (width) => {
    const { currentIndexT, taco_hat_photos } = this.state;

    const prevIndex = currentIndexT - 1 >= 0 ? currentIndexT - 1 : taco_hat_photos.length - 1;
    Animated.timing(this.translateXT, {
      toValue: -width * prevIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndexT: prevIndex });
  };

  sendContact() {
    let { email, message } = this.state;

    if(email && message) {
      let email_valid = Utilities.validateEmail(email);

      if(email_valid) {
        this.setState({contacting: true, error_message: null}, async () => {

          let data = {
            email: 'aleksczajka@gmail.com',
            message: `This person is referring a friend. message details: ${message}<br/><br/>email: ${email}`,
            subject: "Referal message",
          }

          await AdminController.sendEmail(data);

          this.setState({
            sent: true,
            contact_open: false,
            contacting: false
          }, this.referFriend)
        })
      } else {
        this.setState({
          error_message: 'Email field is invalid.',
          contact_open: false
        }, this.referFriend)
      }
    } else {
      this.setState({
        error_message: 'All fields are required.',
        contact_open: false
      }, this.referFriend)
    }
  }

  referFriend() {
    let { contact_open, message, email, contacting, sent, error_message } = this.state;
    let is_mobile = window_width < 500;
    width = 500;

    let height = 'calc(100vh - 40px)';

    let left, top, content_height, content_top_margin, modal_content_style, put_in_header_style, content_wrapper_style, width;
    if(is_mobile) {
      height = window_height;
      left = 0;
      top = 0;
      content_height = height;
      content_top_margin = 0;
      width = window_width;

      content_wrapper_style = {padding: 0, paddingTop: 0}

      modal_content_style = {
        flex: 1,
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: 20,
        zIndex: 999999999,
        top: 0,
        left: 0,
        alignItems: 'center',
        display: 'flex', 
        width, 
        height, 
        position: 'absolute'
      }

      put_in_header_style = {
        flex: 1
        //position: 'absolute', top: 40, left: 40,
      }

    } else {
      top = '20px';
      left = `calc(50% - ${width / 2}px)`;
      content_height = 'calc(80vh - 20px)';
      content_top_margin = 0;

      content_wrapper_style = {padding: 40, paddingTop: 0}

      modal_content_style = {
        flex: 1,
        position: 'absolute',
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: 0,
        zIndex: 200001,
        borderRadius: 10,
        top: 20,
        alignItems: 'center',
        borderRadius: 10,
        display: 'flex', width, height, left, top, position: 'absolute'
      }

      put_in_header_style = {
        flex: 1
        //position: 'absolute', top: 27, left: 20
      }
    }

      this.modal_ref.setContent(
      <View style={[global_style.padding_20, content_wrapper_style, { alignItems: 'center', justifyContent: 'center', textAlign: 'center' }]}>
                    {!sent ?
                    <>
                    <View><Text style={{fontFamily: 'Ysabeau', fontSize: 24}}>Have a friend that would love DiningSocial?</Text></View>
                    <View style={{marginTop: 20, marginBottom: 30}}><Text style={{fontFamily: 'Ysabeau', fontSize: 18}}>We'll reach out to them. If they register and subscribe, you'll get a month off 🙌</Text></View>
                    <Input style={[style.input_box, {width: 300}]} type="email" defaultValue={email} value={email} placeholder="Their email" onChangeText={email => { this.setState({email, contact_open: false}, this.referFriend) }} />
                    <View style={{ height: 20, width: '100%'}}></View>
                    <Input style={[style.input_box, {height: 100, width: 300}]} type="text" multiline={true} defaultValue={message} value={message} placeholder="Let us know any notes and your email." onChangeText={message => { this.setState({message, contact_open: false}, this.referFriend) }} />
                    <View>
                      <View style={{ height: 20, width: '100%'}}></View>
                      {error_message ? <Text style={{fontFamily: 'Comfortaa', color: '#e32652', fontSize: 16, marginBottom: 10}}>{error_message}</Text> : null}
                      <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Refer</Text>}
                        loading={contacting}
                        style={{
                          backgroundColor: '#e32652',
                          borderRadius: 3,
                          paddingTop: 5,
                          paddingBottom: 7,
                          width: 200,
                          paddingHorizontal: 5, marginBottom: 10}}

                        onPress={this.sendContact} />
                      </View>
                    </> : <>
                      <View style={{marginTop: 20, marginBottom: 30}}>
                        <Text style={{fontFamily: 'Ysabeau', fontSize: 29}}>Thank you!</Text>
                        <Text style={{fontFamily: 'Ysabeau', fontSize: 18, marginTop: 50}}>We'll be in contact shortly.</Text>
                      </View>
                    </>}
                    
                  </View>, is_mobile ? window_width : 500, '100vh')
  }

  render() {
    let { rsvps, mounted, email_valid_waitlist, newsletter_added, waitlist_loading, page_index, tab, isLoggedIn, email_register, email_valid_register, email_newsletter, email_valid_newsletter, newsletter_loading, currentIndex, event_dining_photos, next_event_dining_photos, currentIndexN, taco_hat_photos, currentIndexT, member_dining_photos, currentIndexM } = this.state;

    next_event_dining_photos.sort((a, b) => a.order < b.order ? -1 : 1);

    tab = tab ? tab : 0;

    let width = Dimensions.get('window').width;
    let height = Dimensions.get('window').height;

    let is_mobile = width < 500;

    let window_width = width;

    let image_height = 400;

    if(width >= 700 && width < 900){
      image_height = 1200;
    }

    if(width <= 1200 && width > 900){
      image_height = 1000;
    }

    if(width < 700){
      image_height = 800;
    }

    let center_image = require('../../../assets/kitchenstep/two.jpeg');

//require('../../../assets/IMG_1315.jpg'),
//require('../../../assets/IMG_1310.jpg'),
//require('../../../assets/IMG_1313.jpg'),
//require('../../../assets/franklinsocial/IMG_1499.jpg'),

    let instagram_logo = require('../../../assets/instagram_logo.png');
    let facebook_logo = require('../../../assets/facebook_logo.png');

    let main_image = require('../../../assets/main_home.png');
    
    let people_image = require('../../../assets/new_tagline.png');

    width -= 500;
    height -= 100;

    let video_width = 1920;
    let video_height = 1080;

   /*  height / width = 1080 / 1920;
    height = (1080 / 1920) * width; */

    let vid_height = window_width < 700 ? 'calc(100vw * 1080/1920)' : 800 * 1080/1920 ;
    let vid_width = window_width < 700 ? '100vw' : 800 ;

    let testimonials = [{
      photo_url: 'https://diningsocial-assets.s3.amazonaws.com/profile/010e2500-34b0-4bf5-bb29-397b361ff019-1690561085717',
      name: 'Yasmin',
      job: 'Director at Community Based Organization',
      text: 'It was a really good time. It felt like there was space for both structured and unstructured conversations. The host was great and I think a host is a great addition. They can manage any issues that arise with the restaurant and can also jump in if there is a lull and people are not connecting.'
    },
    {
      photo_url: "https://diningsocial-assets.s3.amazonaws.com/profile/f77555a9-fb12-4dcf-afd8-78dec8ec88d1-1691460777683",
      name: 'Jan',
      job: 'Veterinarian',
      text: "I love the ability to experience the amazing food that Jersey City has to offer and meeting new people from all walks of life. Since June, I've met quite a few good friends here that I love getting together with in and outside of Dining Social."
    },
    {
      photo_url: "https://diningsocial-assets.s3.amazonaws.com/profile/23599181-1df8-41b0-9320-abe282997c19-1701109123891",
      name: 'Ajay',
      job: 'ESL Instructor',
      text: 'I like the host leading the discussion and intros. Ice breakers provided good insight into the guests and who they are. It was an informal and fun way to get to know others. The small groups are also something that helps in better conversations. I hope to join more dinners soon!'
    },
    {
      photo_url: "https://diningsocial-assets.s3.amazonaws.com/matt.jpeg",
      name: 'Matt',
      job: 'Actor/Delivery Driver',
      text: 'The restaurant vibe was nice. The group experience was good.  Very fun and engaging. The host was fun and did a great job at getting everyone involved in the conversation, not excluding anyone. I prefer hosted dinners. '
    }]

    let tab_0_style = tab === 0 ? style.tab_selected : style.tab_not_selected;
    let tab_1_style = tab === 1 ? style.tab_selected : style.tab_not_selected;
    let tab_2_style = tab === 2 ? style.tab_selected : style.tab_not_selected;

    let app_mode = is_mobile;

    const pressGear = () => {
      track('Home gear press')
      if(isLoggedIn) {
        window.location.replace('/dashboard/profile')
      } else {
        this.demo_events_ref.openLoginModal();
      }
    }

    let image_width = is_mobile ? window_width : 600;

    return ( mounted ?
      <View ref={ref => this.scrollViewRef = ref} style={{overflow: 'hidden', backgroundColor: '#f7f8fc'}}>
      <View style={style.page_wrapper}>
      <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
        <View style={[style.above_fold, global_style.whole_width_row_mobile_switch_column, {position: 'relative'}]}>
          <View style={style.home_right_main_section}>
            <View style={style.home_main_section}>
              <Image resizeMode='contain' source={people_image}
                style={style.main_callout_image} />
              <View style={style.floating_gradient}></View>
            </View>

            <View style={style.bottom_right_main_section}>
                <TouchableOpacity style={style.top_join_button} onPress={()=>{track('Sign up button - main home'); window.location.replace('/register')}}>
                  <Text style={style.top_join_button_text}>Sign Up</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={()=>{track('Instagram button - main home'); window.open('https://www.instagram.com/diningsocialnj')}}>
                  <Image source={instagram_logo} style={{width: 50, height: 50}} />
                </TouchableOpacity>

                <TouchableOpacity onPress={()=>{track('Calendar button - main home'); window.location.replace('/calendar')}}>
                  {<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="calendar-alt" size={45} color="white" />}
                </TouchableOpacity>
                
                {/* <View style={{width: 50}}>
                <TouchableOpacity onPress={()=>{
                  if (window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches) {
                    alert("You're already using the app.");
                  } else if (window.matchMedia('(display-mode: browser)').matches && window.navigator.standalone !== true) {
                    this.clickDownloadButton()
                  }
                  }}>
                    <Text style={style.download_button}>
                      <MaterialCommunityIcons name="cloud-download-outline" size={24} color="black" />
                    </Text>
                  </TouchableOpacity>
              </View> */}
            </View>
          </View>
          <View style={style.main_carousele_section}>
            <Animated.View
              style={[
                {flexDirection: 'row'},
                { width: 300 * this.image_array_top.length, height: window_width < 700 ? 150 : 200, marginLeft: this.animatedLeftMargin}]} >
          
                    {this.image_array_top.map((image, i) => {
                      return <View style={{
                              marginRight: 0
                          }}>
                        <View
                          style={{
                              flex: 1,
                              //borderWidth: 1,
                              borderRadius: 20,
                              justifyContent: 'center',
                              marginRight: 5
                          }}
                        >
                            {typeof image === 'string' ?

                          <Image resizeMode='cover' source={{uri: image}} style={style.carousel_image} />
                            :
                            <Image resizeMode='cover' source={image} style={style.carousel_image} />}
                        </View>
                      </View>
                    })}
            
            </Animated.View>
            <View style={{marginLeft: window_width < 700 ? -100 : -150}}>
              <Animated.View
                style={[
                  {flexDirection: 'row'},
                  { width: 300 * this.image_array_bottom.length, height: window_width < 700 ? 150 : 200, marginLeft: this.animatedLeftMargin, marginTop: 10}]} >
            
                      {this.image_array_bottom.map((image, i) => {
                        {console.log('image', image)}
                        return <View style={{
                                marginRight: 0
                            }}>
                          <View
                            style={{
                                flex: 1,
                                borderWidth: 1,
                                borderRadius: 20,
                                justifyContent: 'center',
                                marginRight: 5
                            }}
                          >
                               {typeof image === 'string' ?

                            <Image resizeMode='cover' source={{uri: image}} style={style.carousel_image} />
                            :
                            <Image resizeMode='cover' source={image} style={style.carousel_image} />}
                          </View>
                        </View>
                      })}
              
              </Animated.View>
            </View>
          </View>
        </View>

        <View style={{position: 'relative', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#1e1c1c', height: is_mobile ? 'unset' : 400, textAlign: 'center', borderTopWidth: 1, borderTopColor: '#241a1a'}} >
          <View style={{flexDirection: 'column',  alignItems: 'center', width: is_mobile ? '80%' : 'unset', paddingVertical: 80}}>
            <View style={{marginBottom: 20}}><Text style={style.three_title}>Dining Club with Social Principles</Text></View>
            <View style={{marginBottom: 20}}><Text style={{fontFamily: 'Comfortaa', fontSize: 16, color: 'white'}}>Explore</Text></View>
            <View style={{flexDirection: is_mobile ? 'column' : 'row', width: is_mobile ? '80%' : 500, justifyContent: is_mobile ? 'center' : 'space-between', alignItems: is_mobile ? 'center' : 'unset'}}>
              {/* <TouchableOpacity onPress={()=>{track('Our Membership button - home three'); window.location.replace('/membership')}}><Text style={style.three_buttons_button}>Our Membership</Text></TouchableOpacity> */}
              <TouchableOpacity onPress={()=>{track('Our Events button - home three'); this.dining.scrollIntoView()}} ><Text style={style.three_buttons_button}>Our Events</Text></TouchableOpacity>
              <TouchableOpacity onPress={()=>{track('Our Principles button - home three'); this.principles.scrollIntoView()}}><Text style={style.three_buttons_button}>Our Principles</Text></TouchableOpacity>
            </View>
            
          </View>
        </View>
        {/* <View style={{flexDirection: 'row', borderColor: '#9d9b9b', borderBottomWidth: 1}}>
          <Text style={style.section_title}>Join our First Virtual Dinner</Text>
        </View>
        <View style={{flexDirection: 'row', width: is_mobile ? '100%' : '100%', borderBottomWidth: 1, borderColor: '#9d9b9b'}}>
          <View style={[{position: 'relative', overflow: 'hidden', flexDirection: is_mobile ? 'column' : 'row', width: is_mobile ? '100%' : '100%'}]}>
            <View style={{width: is_mobile ? '100%' : 500, borderRightWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b'}}>
              <View style={{margin: 40}}>
                <Text style={[style.dining_sub_title, {fontSize: 20, fontFamily: 'Comfortaa'}]}>
                  August 28th in Your Kitchen
                </Text>
                <Text style={style.dining_text}>
                Dining out at restaurants can be not only expensive but also require a lot of effort. To accomodate a wider range of our members, we're launching a virtual series where locals gather over dinner in their own kitchen! Guided by our friendly host, we'll prepare a small dish together and mingle over the experience. Each week we'll have a different theme and welcome different hosts. Come join us!
                </Text>
                <View style={{flexDirection: 'row', justifyContent: is_mobile ? 'center' : 'unset', marginTop: 10}}>
                  <TouchableOpacity onPress={()=>{track('Big Dinner - Event Dining'); window.location.replace('/virtual/88b0a201-1a3f-4581-8685-925aa2375481')}} ><Text style={[style.four_buttons_button, {width: 300, color: 'white', borderColor: '#e32652', marginTop: 20, backgroundColor: '#e32652', borderRadius: 2, textAlign: 'center'}]}>More Details & RSVP</Text></TouchableOpacity>
                </View>
              </View>
            </View>
            <View style={{position: 'relative', width: is_mobile ? 'unset' : 600, overflow: 'hidden', margin: is_mobile ? 20 : 40, marginHorizontal: 'auto'}}>
              <Animated.View style={[{flexDirection: 'row',
                  width: image_width * next_event_dining_photos.length
                  }, { transform: [{ translateX: this.translateXN }] }]}>
                  {<Image source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/virtual_1.png'}} style={[style.our_dining_image, {width: image_width
                  }]} resizeMode='cover' />}
              </Animated.View>
            </View>
          </View>
        </View> */}
    {/* <View style={{position: 'relative', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#1e1c1c', height: is_mobile ? 'unset' : 300, textAlign: 'center', paddingVertical: is_mobile ? 50 : 'unset'}} >
          <View style={{flexDirection: 'column',  alignItems: 'center', width: '100%'}}>
            <View style={{marginBottom: 40}}><Text style={[style.four_title, {fontSize: 30}]}>Let Us Know When You're Available</Text></View>
            <View style={{flexDirection: 'row', width: is_mobile ? 'unset' : 595, justifyContent: 'center'}}>
              <TouchableOpacity onPress={()=>{track('calendar - button home'); window.location.replace('/calendar')}} ><Text style={[style.four_buttons_button, {width: 300}]}>Our Calendar</Text></TouchableOpacity>
            </View>
            
          </View>
        </View> */}
        <View style={{flexDirection: 'row', borderColor: '#9d9b9b', borderBottomWidth: 1}}>
          <Text style={style.section_title}>Our Next Group Brunch</Text>
        </View>
        <View style={{flexDirection: 'row', width: is_mobile ? '100%' : '100%', borderBottomWidth: 1, borderColor: '#9d9b9b'}}>
          <View style={[{position: 'relative', overflow: 'hidden', flexDirection: is_mobile ? 'column' : 'row', width: is_mobile ? '100%' : '100%'}]}>
            <View style={{width: is_mobile ? '100%' : 500, borderRightWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b'}}>
              <View style={{margin: 40}}>
                <Text style={[style.dining_sub_title, {fontSize: 26, fontFamily: 'Comfortaa'}]}>
                  Unlimited Bubbles Brunch - Oct 13th at Halifax
                </Text>
                <Text style={style.dining_text}>
                  Join our next group event on Sunday, Oct 13th 😃🥞. This brunch is open to 10+ people and will involve us all sitting down together in groups of 4. We will have icebreakers and a friendly, attentive host that will mix the groups up so that everyone has the ability to meet each other. This brunch is particularly special because it will take place at Halifax, offering an unlimited brunch for two hours.
                </Text>
                <View style={{flexDirection: 'row', justifyContent: is_mobile ? 'center' : 'unset', marginTop: 10}}>
                  <TouchableOpacity onPress={()=>{track('Big Dinner - Event Dining'); window.location.replace('/event/567d059b-33e3-496f-a995-f2373e5e6319')}} ><Text style={[style.four_buttons_button, {width: 300, color: 'white', borderColor: '#e32652', marginTop: 20, backgroundColor: '#e32652', borderRadius: 2, textAlign: 'center'}]}>More Details & RSVP</Text></TouchableOpacity>
                </View>
              </View>
            </View>
            <View style={{position: 'relative', width: is_mobile ? 'unset' : 600, overflow: 'hidden', margin: is_mobile ? 20 : 40, marginHorizontal: 'auto'}}>
              <Animated.View style={[{flexDirection: 'row',
                  width: image_width * next_event_dining_photos.length
                  }, { transform: [{ translateX: this.translateXN }] }]}>
                  {next_event_dining_photos.map((photo, index) => {
                    let shouldPlay = currentIndexN === index;

                    if(index === 1) {
                      console.log('index', index);
                      console.log('currentIndexN', currentIndexN);
                      console.log('photo', photo);

                      console.log('shouldPlay', shouldPlay)
                    }

                  return photo.type === 'movie' ?
                  <View>
                      <Video
                        source={{ uri: photo.uri }}
                        style={[style.our_dining_image, {width: image_width
                      }]}
                        videoStyle={{position: 'relative'}}
                        useNativeControls={false}
                        loop={true}
                        shouldPlay={shouldPlay}
                        resizeMode="cover"
                        rate={.7}
                        volume={0}
                        isMuted={true}
                      />
                    </View>
                  :
                  <Image key={index} source={{ uri: photo.uri }} style={[style.our_dining_image, {width: image_width
                  }]} resizeMode='cover' />
                })}
              </Animated.View>
              <View style={{flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', marginTop: -50, width: 120, marginLeft: is_mobile ? (window_width / 2) - 60 : 'calc(50% - 60px)'}}>
                <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-left" size={34} color="white" />}
                loading={false} onPress={()=>this.handlePrevPressN(image_width)} />
                <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-right" size={34} color="white" />}
                loading={false} onPress={()=>this.handleNextPressN(image_width)} />
              </View>

              {/* <TouchableOpacity onPress={()=>{track('Big Dinner - Event Dining'); window.location.replace('/event/567d059b-33e3-496f-a995-f2373e5e6319')}} style={{width: is_mobile ? 'calc(100vw - 20px)' : '100%', margin: 10, marginTop: 40}}>
              <Text style={{fontFamily: 'Ysabeau', fontSize: 20, marginBottom: 10}}>RSVPs</Text>
                <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                {rsvps.map(event_rsvp=>{
                  let img_size = 50;
                    let { profile } = event_rsvp;
                    profile = profile  ? profile : `{"name": "Member", "user_id": null, "photo_url": "https://diningsocial-assets.s3.amazonaws.com/empty_profile.png"}`
                    let { photo_url, name, user_id } = JSON.parse(profile);
                  return <View style={{flexDirection: 'column', width: img_size + 10}}>
                    <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
                    height: img_size,
                    borderRadius: 100}} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
                    </View>
                    
                })}
                </View>
              </TouchableOpacity> */}
            </View>
          </View>
        </View>
        <View style={{position: 'relative', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#1e1c1c', height: is_mobile ? 'unset' : 300, textAlign: 'center', borderTopWidth: 1, borderTopColor: '#9d9b9b', paddingVertical: is_mobile ? 50 : 'unset'}} >
          <View style={{flexDirection: 'column',  alignItems: 'center', width: '100%'}}>
            <View style={{marginBottom: 40}}><Text style={style.four_title}>Explore More Events</Text></View>
            <View style={{flexDirection: 'row', width: is_mobile ? 'unset' : 595, justifyContent: 'center'}}>
              <TouchableOpacity onPress={()=>{track('Explore Events - Calendar'); window.location.replace('/calendar')}} ><Text style={[style.four_buttons_button, {width: 300}]}>Explore Events</Text></TouchableOpacity>
            </View>
            
          </View>
        </View>
        {/* <View style={{flexDirection: 'row', width: is_mobile ? 'calc(100vw - 20px)' : '100%', borderBottomWidth: 1, borderColor: '#9d9b9b'}}>
      <View style={[{position: 'relative', overflow: 'hidden', flexDirection: is_mobile ? 'column' : 'row', width: is_mobile ? 'calc(100vw)' : '100%'}]}>
        <View style={{width: is_mobile ? '100%' : '45%', borderRightWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b'}}>
          <View style={{margin: 40, marginTop: 40}}>
          <Text style={[style.dining_sub_title]}>
                  Goal of A 1,000 Members
                </Text>
                <Text style={[style.dining_text, {marginTop: 40}]}>
                  We're striving to reach 1,000 registered members! 🙌 Once we hit this milestone, our members will have the ability to specify whom they'd like to meet and be paired up for meals based on their preferences. Currently, our meals are organized on a first-come, first-serve basis, allowing you to RSVP for any dinner or brunch on our calendar. Help us achieve this goal by registering for free and referring new members. And, of course, catch me wearing the Taco Hat promoting Dining Social around town!
                </Text>
                
            <View style={{flexDirection: is_mobile ? 'column' : 'row', justifyContent: is_mobile ? 'center' : 'space-between', paddingTop: 50}}>
              <TouchableOpacity onPress={()=>{track('Home page - register 1000 members'); window.location.replace('/register')}}><Text style={{fontFamily: 'Comfortaa', fontSize: 20, color: 'white', backgroundColor: 'black', paddingVertical: 20, paddingHorizontal: 40, textAlign: 'center' }}>Register for Free</Text></TouchableOpacity>
              <TouchableOpacity onPress={()=>{track('Home page - refer friend open');this.referFriend()}}><Text style={{fontFamily: 'Comfortaa', fontSize: 20, color: 'white', backgroundColor: 'black', paddingVertical: 20, paddingHorizontal: 40, textAlign: 'center', marginTop: is_mobile ? 20 : 'unset' }}>Refer a Friend</Text></TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={{position: 'relative', width: is_mobile ? 'unset' : '55%', overflow: 'hidden', margin: is_mobile ? 0 : 'unset', marginVertical: is_mobile ? 20 : 20, marginBottom: is_mobile ? 50 : 40}}>

          <View style={{position: 'relative', width: is_mobile ? 'unset' : image_width, overflow: 'hidden', margin: is_mobile ? 0 : 40}}>
            <Animated.View style={[{flexDirection: 'row',
                width: image_width * taco_hat_photos.length
                }, { transform: [{ translateX: this.translateXT }] }]}>
                {taco_hat_photos.map((photo, index) => {
                return photo.type === 'movie' ?
                    <Video
                      source={{ uri: photo.uri }}
                      style={[style.our_dining_image, {width: image_width
                }]}
                      videoStyle={{position: 'relative'}}
                      useNativeControls={true}
                      spee
                      shouldPlay={currentIndexT === index}
                      resizeMode="cover"
                      rate={.7}
                      volume={0}
                      isMuted={true}
                    />
                :
                <Image key={index} source={{ uri: photo.uri }} style={[style.our_dining_image, {width: image_width
                }]} resizeMode={is_mobile ? 'contain' : 'cover'} />
              })}
            </Animated.View>
            <View style={{flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', width: 120, position: 'absolute', bottom: is_mobile ? 15 : 40, left: 'calc(50% - 60px)'}}>
              <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-left" size={34} color="white" />}
              loading={false} onPress={()=>this.handleNextPressT(image_width)} />
              <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-right" size={34} color="white" />}
              loading={false} onPress={()=>this.handleNextPressT(image_width)} />
            </View>
          </View>
            
          <View style={{padding: is_mobile ? 20 : 40, marginHorizontal: is_mobile ? 20 : 60, marginTop: 0, borderRadius: 10, backgroundColor: '#6ddaf4'}}>
              <Text style={{fontFamily: 'Comfortaa', fontSize: is_mobile ? 30 : 30, textAlign: 'center', textTransform: 'uppercase', lineHeight: is_mobile ? 40 : 'unset'}}>Current Members: <Text>{member_count}</Text></Text>
          </View>
        </View>
      </View>
    </View> */}

        <View>
          <View style={{flexDirection: 'column'}}>
            <View ref={node=>this.dining=node} style={{flexDirection: 'row', borderColor: '#9d9b9b', borderBottomWidth: 1}}>
              <Text style={style.section_title}>Our Dining</Text>
              <View style={{justifyContent: 'center', alignContent: 'center'}}>
                {is_mobile ? <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 20}}>
                  <TouchableOpacity onPress={()=>{track('Calendar button - Our Dining'); window.location.replace('/calendar')}}>
                    {<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="calendar-alt" size={45} color="black" />}
                  </TouchableOpacity>
                </View> : <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <Text style={{marginLeft: 20, marginRight: 20}}>View our calendar</Text>
                  <TouchableOpacity onPress={()=>{track('Calendar button - Our Dining'); window.location.replace('/calendar')}}>
                    {<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="calendar-alt" size={45} color="black" />}
                  </TouchableOpacity>
                </View>
                }
              </View>
            </View>
            
            <View  style={{flexDirection: is_mobile ? 'column' : 'row'}}>
              <View style={{flexDirection: 'column', width: is_mobile ? '100%' : 615, borderRightWidth: 1, borderColor: '#9d9b9b'}}>
                <View style={[style.wrap_dining_image, {position: 'relative', overflow: 'hidden'}]}>
                  <View style={{position: 'relative'}}>
                    <Animated.View style={[{flexDirection: 'row',
                        width: 495 * member_dining_photos.length
                        }, { transform: [{ translateX: this.translateXM }] }]}>
                        {member_dining_photos.map((photo, index) => {
                        return photo.type === 'movie' ?
                            <Video
                              source={{ uri: photo.uri }}
                              style={[style.our_dining_image, {width: 495
                        }]}
                              videoStyle={{position: 'relative'}}
                              useNativeControls={true}
                              spee
                              shouldPlay={currentIndexM === index}
                              resizeMode="cover"
                              rate={.7}
                              volume={0}
                              isMuted={true}
                            />
                        :
                        <Image key={index} source={{ uri: photo.uri }} style={[style.our_dining_image, {width: 495
                        }]} resizeMode='cover' />
                      })}
                    </Animated.View>
                    <View style={{flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', marginTop: 10, width: 120, position: 'absolute', bottom: 40, left: 'calc(50% - 60px)'}}>
                      <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-left" size={34} color="white" />}
                      loading={false} onPress={()=>this.handlePrevPressM(495)} />
                      <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-right" size={34} color="white" />}
                      loading={false} onPress={()=>this.handleNextPressM(495)} />
                    </View>
                  </View>

                  <View>
                    <Text style={[style.dining_sub_title, {width: '100%'}]}>
                      Member Dining
                    </Text>
                    <Text style={style.dining_text}>
                      Member Dining is our main forte. Get together in groups of 3-6 members over brunch or dinner at participating restaurants in NJ. Member dining is absolutely free and requires no subscription. These events are placed on the calendar based on member availability. So, let us know when you're available!
                    </Text>
                    <View style={{flexDirection: is_mobile ? 'column' : 'row', justifyContent: is_mobile ? 'center' : 'space-between', alignItems: is_mobile ? 'center' : 'unset'}}>
                      <TouchableOpacity onPress={()=>{track('Availability - Member Dining'); window.location.replace('/calendar')}} ><Text style={[style.four_buttons_button, {width: 300, color: 'black', borderColor: 'black', marginTop: 20, textAlign: 'center'}]}>Set Availability</Text></TouchableOpacity>
                      {/* {is_mobile ? <TouchableOpacity onPress={()=>{track('Calendar - Member Dining'); window.location.replace('/calendar')}} ><Text style={[style.four_buttons_button, {width: 300, color: 'black', borderColor: 'black', marginTop: 20, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', alignContent: 'center', display: 'flex', paddingHorizontal: 40}]}>Explore Calendar <FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="calendar-alt" size={35} color="black" /></Text></TouchableOpacity> : 
                      <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignSelf: 'flex-end', marginRight: 20}}>
                        <TouchableOpacity onPress={()=>{track('Calendar button - Member Dining'); window.location.replace('/calendar')}}>
                          {<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="calendar-alt" size={45} color="black" />}
                        </TouchableOpacity>
                      </View>} */}
                    </View>
                  </View>
                </View>
              </View>
              <View style={{flexDirection: 'column', width: is_mobile ? '100%' : 615}}>
                <View style={[style.wrap_dining_image, {position: 'relative', overflow: 'hidden'}]}>
                  <View style={{position: 'relative'}}>
                    <Animated.View style={[{flexDirection: 'row',
                        width: 495 * event_dining_photos.length
                        }, { transform: [{ translateX: this.translateX }] }]}>
                        {event_dining_photos.map((photo, index) => {
                        return photo.type === 'movie' ?
                            <Video
                              source={{ uri: photo.uri }}
                              style={[style.our_dining_image, {width: 495
                        }]}
                              videoStyle={{position: 'relative'}}
                              useNativeControls={true}
                              spee
                              shouldPlay={currentIndex === index}
                              resizeMode="cover"
                              rate={.7}
                              volume={0}
                              isMuted={true}
                            />
                        :
                        <Image key={index} source={{ uri: photo.uri }} style={[style.our_dining_image, {width: 495
                        }]} resizeMode='cover' />
                      })}
                    </Animated.View>
                    <View style={{flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', marginTop: 10, width: 120, position: 'absolute', bottom: 40, left: 'calc(50% - 60px)'}}>
                      <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-left" size={34} color="white" />}
                      loading={false} onPress={()=>this.handlePrevPress(495)} />
                      <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-right" size={34} color="white" />}
                      loading={false} onPress={()=>this.handleNextPress(495)} />
                    </View>
                  </View>
                  <View>
                    <Text style={style.dining_sub_title}>
                      Hosted Dining
                    </Text>
                    <Text style={style.dining_text}>
                      We also host events that are organized by and involve one of our friendly hosts. At these events, our host manages the group, facilitates conversation and helps members meet each other. These events usually involve a bigger group of attendees and are great for those who are looking to meet new people in a larger group setting.
                    </Text>
                    <View style={{flexDirection: 'row', justifyContent: is_mobile ? 'center' : 'unset'}}>
                      <TouchableOpacity onPress={()=>{track('Big Dinner - Event Dining'); window.location.replace('/event/567d059b-33e3-496f-a995-f2373e5e6319')}} ><Text style={[style.four_buttons_button, {width: 320, color: 'black', borderColor: 'black', marginTop: 20, textAlign: 'center'}]}>Our Next Hosted Brunch</Text></TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={{position: 'relative', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#1e1c1c', height: is_mobile ? 'unset' : 400, textAlign: 'center', borderTopWidth: 1, borderTopColor: '#9d9b9b', paddingVertical: is_mobile ? 50 : 'unset'}} >
          <View style={{flexDirection: 'column',  alignItems: 'center', width: '100%'}}>
            <View style={{marginBottom: 40}}><Text style={style.four_title}>Join Dining Social</Text></View>
            <View style={{flexDirection: 'row', width: is_mobile ? 'unset' : 595, justifyContent: 'center'}}>
              <TouchableOpacity onPress={()=>{track('Register Free - Join Dining Socail'); window.location.replace('/register')}} ><Text style={[style.four_buttons_button, {width: 300}]}>Register for Free</Text></TouchableOpacity>
            </View>
            
          </View>
        </View>

        <View>
          <View style={{flexDirection: 'column'}}>
            <View ref={node=>this.principles=node} style={{flexDirection: 'row', borderColor: '#9d9b9b', borderBottomWidth: 1}}>
              <Text style={style.section_title}>Our Principles</Text>
              <View style={{justifyContent: 'center', alignContent: 'center'}}>
                {is_mobile ? null : <Text style={{marginLeft: 20}}>See how our principles directly stem from 
                  <TouchableOpacity onPress={()=>{track('Our Mission - Our Principles'); window.location.replace('/mission')}} ><Text style={{borderWidth: 1, padding: 10, marginLeft: 20}}>our mission</Text></TouchableOpacity>
                </Text>}
              </View>
              
            </View>
            <View  style={{flexDirection: is_mobile ? 'column' : 'row'}}>
              <View style={{flexDirection: 'column', flex: 1}}>
                <View style={style.wrap_dining_image}>
                
                  <Video
                      source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/social_effort.mov' }}
                      style={[style.our_dining_image]}
                      videoStyle={{position: 'relative'}}
                      useNativeControls={true}
                      spee
                      shouldPlay={this.state.effortVisible}
                      resizeMode="cover"
                      rate={.7}
                      volume={0}
                      isMuted={true}
                    />
                    <div id="effortElement"></div>
                  <View name={'effortElement'} testID={'effortElement'} key={'effortElement'} id="effortElement" ref={node=>this.elementRef=node}>
                    <Text style={style.dining_sub_title_two}>
                      Social Effort
                    </Text>
                    <Text style={style.dining_text}>
                      We're all responsible for the atmosphere we're in. Let's make each other feel welcome and respected. When you come to our DiningSocial outings, we ask that you put in an effort to participate in the conversation by listening and following up with questions. 
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{flexDirection: 'column', flex: 1}}>
                <View style={style.wrap_dining_image}>
                  <Image style={style.our_dining_image} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/m_six.png'}} />
                  <View>
                    <Text style={style.dining_sub_title_two}>
                      Curiosity
                    </Text>
                    <Text style={style.dining_text}>
                      Each one of us has a unique story. A person might look like someone you know, but assuming that they are the same is an erroneous notion. Be curious about the people around you and find out who they are.
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{flexDirection: 'column', flex: 1}}>
                <View style={style.wrap_dining_image}>
                  <Video
                      source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/open_networking.mov' }}
                      style={[style.our_dining_image]}
                      videoStyle={{position: 'relative'}}
                      useNativeControls={true}
                      shouldPlay={this.state.networkingVisible}
                      resizeMode="cover"
                      rate={.7}
                      volume={0}
                      isMuted={true}
                    />
                  <View>
                    <Text style={style.dining_sub_title_two}>
                      Open Networking
                    </Text>
                    <Text style={style.dining_text}>
                      Networking isn't transactional. By only talking to the people that you're looking to meet, you're limiting your chances of achieving that goal. Everyone comes in with their own respective network. By communicating your goals clearly and finding out about theirs, you both open up your networks to each other.
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{position: 'relative', justifyContent: 'center', alignItems: 'center', width: is_mobile ? '100%': '80%', backgroundColor: '#1e1c1c', height: 200, textAlign: 'center', margin: 'auto', borderTopWidth: 1, borderTopColor: '#9d9b9b', marginBottom: 100, marginTop: is_mobile ? 20 : 'unset'}} >
              <Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 20, marginBottom: 20}}>See how our principles directly link to our mission.</Text>
              <TouchableOpacity onPress={()=>{track('Our Mission - Our Principles 2'); window.location.replace('/mission')}} ><Text style={[style.three_buttons_button, {width: 300}]}>Our Mission in Our Principles</Text></TouchableOpacity>
            </View>
          </View>
        </View>

        <View>
          <View style={{flexDirection: 'column'}}>
            <View style={{flexDirection: 'row', borderColor: '#9d9b9b', borderBottomWidth: 1, borderTopWidth: 1}}><Text style={style.section_title}>Member Testimonials</Text></View>
            <View  style={{flexDirection: 'row'}}>
              <View style={{flexDirection: 'column', flex: 1}}>


                  <View style={style.testimonials_top_wrapper}>
                  <View style={style.testimonial_first_row}>
                    {is_mobile ?
                    <View style={style.testimonial_first_tile}>
                      <View style={style.testimonial_border}>
                        <Text style={style.testimonial_summary_text}>
                        <View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20, float: 'right'}}>
                          <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9aa1513b-30fe-407f-9d23-3c7c083bd562-1706484409682'}} />
                          <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                            Sachin
                          </Text>
                        </View>"I get to sit down in a small group and actually get to know people without the interruptions of a larger and louder event."
                        </Text>
                        
                      </View>
                    </View> : <View style={style.testimonial_first_tile}>
                      <View style={style.testimonial_border}>
                        <Text style={style.testimonial_summary_text}>"I get to sit down in a small group and actually get to know people without the interruptions of a larger and louder event."</Text>
                        <View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20}}>
                          <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9aa1513b-30fe-407f-9d23-3c7c083bd562-1706484409682'}} />
                          <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                            Sachin
                          </Text>
                        </View>
                      </View>
                    </View>}

                    {is_mobile ?
                    <View style={style.testimonial_height}>
                    <View style={style.testimonial_border}>
                        <Text style={style.testimonial_summary_text}><View style={{textAlign: 'center', alignItems: 'center', marginRight: 20, float: 'left'}}>
                          <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/06e18bef-a7c1-47bd-9a22-740ae015a13c-1705858014214'}} />
                          <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                            Moi
                          </Text>
                        </View>"I get to develop friendships over time instead of forcing a connection from one event."</Text>
                      </View>
                    </View> : <View style={style.testimonial_height}>
                    <View style={style.testimonial_border}>
                        <Text style={style.testimonial_summary_text}>"I get to develop friendships over time instead of forcing a connection from one event."</Text>
                        <View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20}}>
                          <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/06e18bef-a7c1-47bd-9a22-740ae015a13c-1705858014214'}} />
                          <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                            Moi
                          </Text>
                        </View>
                      </View>
                    </View>}
                  </View>
                  <View style={global_style.row_mobile_switch_column}>
                    {is_mobile ? 
                    <View style={style.testimonial_third_tile}>
                      <View style={style.testimonial_border}>
                          
                          <Text style={style.testimonial_summary_text}><View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20, float: 'right'}}>
                            <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9b020c06-46be-47f8-b6f5-323686328e07-1701459266114'}} />
                            <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                              Samantha
                            </Text>
                          </View>"I feel very welcome when I know it will just be a few of us chatting in a cozy setting."</Text>
                        </View>
                      </View> : <View style={style.testimonial_third_tile}>
                      <View style={style.testimonial_border}>
                          <View style={{textAlign: 'center', alignItems: 'center', marginRight: 20}}>
                            <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9b020c06-46be-47f8-b6f5-323686328e07-1701459266114'}} />
                            <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                              Samantha
                            </Text>
                          </View>
                          <Text style={style.testimonial_summary_text}>"I feel very welcome when I know it will just be a few of us chatting in a cozy setting."</Text>
                        </View>
                      </View>}
                      {is_mobile ? 
                      <View style={style.testimonial_height}>
                      <View style={style.testimonial_border}>
                          <Text style={style.testimonial_summary_text}><View style={{textAlign: 'center', alignItems: 'center', marginRight: 20, float: 'left'}}>
                            <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/6552aad9-a3c7-4b3c-8306-8eb9d671ffd0-1705169404445'}} />
                            <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                              Karan
                            </Text>
                          </View>"I'm single and I like the fact that I get to meet people as friends first without the commitment of a date."</Text>
                        </View>
                      </View> : <View style={style.testimonial_height}>
                      <View style={style.testimonial_border}>
                          <View style={{textAlign: 'center', alignItems: 'center', marginRight: 20}}>
                            <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/6552aad9-a3c7-4b3c-8306-8eb9d671ffd0-1705169404445'}} />
                            <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                              Karan
                            </Text>
                          </View>
                          <Text style={style.testimonial_summary_text}>"I'm single and I like the fact that I get to meet people as friends first without the commitment of a date."</Text>
                        </View>
                      </View>}
                  </View>
                </View>
              </View>
            </View>
            <View>
            <View style={{flexDirection: 'row', borderColor: '#9d9b9b', borderBottomWidth: 1, borderTopWidth: 1}}><Text style={style.section_title}>Member Callouts</Text></View>
                  <View style={{position: 'relative'}}>
                    <View style={{}}>
                      </View>

                      {window_width > 700 ?
                      <>
                        <Carousel panGestureHandlerProps={{
                              activeOffsetX: [-10, 10],
                          }} ref={node=>this.calendar_ref=node}
                          mode="parallax"
                          width={window_width}
                          height={600}
                          autoPlay={false}
                          style={{padding: 0, margin: 0, backgroundColor: 'white'}}
                          scrollAnimationDuration={1000}
                          onSnapToItem={(page_index) => {this.setState({page_index})}}
                          pagingEnabled={true}
                          snapEnabled={true}
                          overscrollEnabled={true}
                          enabled={true}
                          data={testimonials}
                          renderItem={({ index }) => (
                            <View>
                              {index % 2 === 0 ? <View style={[style.explainer_one_full_cover, {backgroundColor: 'black', borderRadius: 10}]}>
                                  <View style={style.testimonial_explainer_one} >
                                    <View style={style.explainer_image_parent}>
                                      <View style={style.testimonial_explainer_image_cover_one}><Image style={style.explainer_image_one} resizeMode={'cover'} source={{uri: testimonials[index].photo_url}} /></View>
                                    </View>
                                    <View style={style.testimonial_explainer_text_cover_one}>
                                      <Text style={style.explainer_text}><Text style={[style.testimonial_quote, {marginRight: 10}]}><Icon size={20} color={'white'} name="quote-left" /></Text>{testimonials[index].text}<Text style={[style.testimonial_quote, {marginLeft: 10}]}><Icon size={20} color={'white'} name="quote-right" /></Text></Text>
                                      <Text style={style.testimonial_name}>{testimonials[index].name} <Text style={style.testimonial_job}>{testimonials[index].job}</Text></Text>
                                      <View>
                                      </View>
                                    </View>
                                  </View>
                                </View> : <View style={[style.explainer_one_full_cover, {backgroundColor: 'black', borderRadius: 10}]}>
                                  <View style={style.testimonial_explainer_one}>
                                    <View style={style.testimonial_explainer_text_cover_two}>
                                      <Text style={style.explainer_text}><Text style={[style.testimonial_quote, {marginRight: 10}]}><Icon size={20} color={'white'} name="quote-left" /></Text>{testimonials[index].text}<Text style={[style.testimonial_quote, {marginLeft: 10}]}><Icon size={20} color={'white'} name="quote-right" /></Text></Text>
                                      <Text style={style.testimonial_name}>{testimonials[index].name} <Text style={style.testimonial_job}>{testimonials[index].job}</Text></Text>
                                      <View>
                                      </View>
                                    </View>
                                    <View style={style.explainer_image_parent}>
                                      <View style={style.testimonial_explainer_image_cover_two}><Image style={style.explainer_image_one} resizeMode={'cover'} source={{uri: testimonials[index].photo_url}} /></View>
                                    </View>
                                  </View>
                                </View>}
                              </View>
                            )} />
                          <TouchableOpacity style={{position: 'absolute', top: 400, left: 40, width: 41,  height: 50, borderRadius: 100, zIndex: 1}} onPress={this.carouselPrev}><View style={[style.paging_button]}><Icon style={{marginLeft: -5}} size={40} color={'#949aa4'} name="caret-left" /></View></TouchableOpacity>

                          <TouchableOpacity style={{position: 'absolute', top: 400, right: 40, width: 41, marginLeft: 5, height: 50, borderRadius: 100, zIndex: 1}} onPress={this.carouselNext}><View style={[style.paging_button]}><Icon size={40} style={{marginLeft: 5}} color={'#949aa4'} name="caret-right" /></View></TouchableOpacity>
                          </>
                      : <Carousel
                        panGestureHandlerProps={{
                            activeOffsetX: [-10, 10],
                        }}
                        ref={node=>this.calendar_ref=node}
                        mode="parallax"
                        width={window_width}
                        height={800}
                        autoPlay={false}
                        style={{padding: 0, margin: 0, backgroundColor: 'white'}}
                        scrollAnimationDuration={1000}
                        onSnapToItem={(page_index) => {this.setState({page_index})}}
                        pagingEnabled={true}
                        snapEnabled={true}
                        overscrollEnabled={true}
                        enabled={true}
                        data={testimonials}
                        renderItem={({ index }) => (
                          <View>
                            <View style={[style.explainer_one_full_cover, {backgroundColor: 'black', borderRadius: 10}]}>

                                  <View style={style.testimonial_explainer_image_cover_one}>
                                      <Image style={style.explainer_image_one} resizeMode={'cover'} source={{uri: testimonials[index].photo_url}} />
                                  </View>
                            
                                  <View style={style.testimonial_explainer_text_cover_one}>
                                    <Text style={style.explainer_text}><Text style={[style.testimonial_quote, {marginRight: 10}]}><Icon size={20} color={'white'} name="quote-left" /></Text>{testimonials[index].text}<Text style={[style.testimonial_quote, {marginLeft: 10}]}><Icon size={20} color={'white'} name="quote-right" /></Text></Text>
                                    <Text style={style.testimonial_name}>{testimonials[index].name} <Text style={style.testimonial_job}>{testimonials[index].job}</Text></Text>
                                    <View>
                                    </View>
                                  </View>
                              </View>
                            </View>
                          )}
                      />}
                  </View> 
                </View>
        
        <View style={{backgroundColor: 'white', paddingBottom: 50}}>
          <View style={style.pagginator_wrapper}>
            <TouchableOpacity style={style.page_button} onPress={()=>{this.scrollCarousel(0)}}>
              {page_index === 0 ? <View style={[style.page_button, style.selected_button]}></View> : <View style={style.page_button}></View>}
            </TouchableOpacity>
            <TouchableOpacity style={style.page_button} onPress={()=>{this.scrollCarousel(1)}}>
              {page_index === 1 ? <View style={[style.page_button, style.selected_button]}></View> : <View style={style.page_button}></View>}
            </TouchableOpacity>
            <TouchableOpacity style={style.page_button} onPress={()=>{this.scrollCarousel(2)}}>
              {page_index === 2 ? <View style={[style.page_button, style.selected_button]}></View> : <View style={style.page_button}></View>}
            </TouchableOpacity>
            <TouchableOpacity style={style.page_button} onPress={()=>{this.scrollCarousel(3)}}>
              {page_index === 3 ? <View style={[style.page_button, style.selected_button]}></View> : <View style={style.page_button}></View>}
            </TouchableOpacity>
          </View>
        </View>
        </View>
        </View>
  
        <View style={{}}>
        <View style={{flexDirection: 'row', borderColor: '#9d9b9b', borderBottomWidth: 1, borderTopWidth: 1}}><Text style={style.section_title}>Founder Story</Text></View>
                      <View style={{flexDirection: is_mobile ? 'column' : 'row', borderBottomWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b'}}>
                   
                          <View style={{width: is_mobile ? '100%' : '60%'}}>
                            <View style={{marginBottom: 50, marginLeft: is_mobile ? 20 : 50, marginRight: is_mobile ? 20 : 50, marginTop: 50}}>
                              <Image style={{height: is_mobile ? 200 : 400, width: '100%'}} resizeMode='cover' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/AboutImage.jpeg'}} />
                              <Text style={{color: 'white', backgroundColor: 'black', padding: 3, fontFamily: 'Ysabeau', fontSize: 18, paddingLeft: 10}}>Lisa, Aleksandra & Jan at Frankie, Sept. '23</Text>
                            </View>
                          </View>
                          <View style={{width: is_mobile ? '100%' : '40%', borderLeftWidth: is_mobile ? 0 : 1, justifyContent: 'center', borderColor: '#9d9b9b'}}>
                            <View style={{paddingLeft: is_mobile ? 0 : 50}}>
                              <Text style={{fontFamily: 'Ysabeau', fontSize: is_mobile ? 20 : 45, textTransform: 'uppercase', marginBottom: 0, textAlign: is_mobile ? 'center' : 'unset'}}>Welcome!</Text>
                              <Text style={{fontFamily: 'Ysabeau', fontSize: is_mobile ? 40 : 50, textTransform: 'uppercase', marginBottom: 20, textAlign: is_mobile ? 'center' : 'unset' }}>I'm Aleksandra</Text>
                              <View style={{height: is_mobile ? 'unset' : 230}}>
                                <Text style={[style.dining_text, {marginRight: is_mobile ? 20 : 50, marginLeft: is_mobile ? 10 : 'unset'}]}>
                                  I started dining social because I was tired of going out to bars, cafes, networking events and never making any real connections. I want to create a space where you can grab dinner or brunch any day of the week and know that the people there will be excited to meet you. 
                                </Text>
                                <View style={{flexDirection: 'row', marginTop: 20, justifyContent: is_mobile ? 'center' : 'unset'}}>
                                  <TouchableOpacity onPress={()=>{track('Founder Story - Read More'); window.location.replace('/mission')}} ><Text style={[style.four_buttons_button, {width: 300, color: 'black', borderColor: 'black', marginTop: 20, textAlign: 'center'}]}>Read More</Text></TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                          
                      </View>
                    </View>


        <View style={{width: is_mobile ? 'calc(100vw - 40px)' : 600, marginHorizontal: 40, alignSelf: 'center', flex: 1, paddingVertical: 50}}>
                    <View style={[global_style.column, global_style.flex_one, style.newsletter_column]}>
                    <Text style={style.newsletter_intro_title}>Sign up for Our Newsletter</Text>
                  <View style={[global_style.row, style.input_spacing]}>
                    <Input
                      style={style.input_box} 
                      type="email" value={email_newsletter}  
                      placeholder="Your email" 
                      onChangeText={event => { this.updateEmail(event, 'newsletter') }}
                      onTextInput={event => { this.updateEmail(event, 'newsletter') }}
                      onLayout={() => {}}
                      keyboardType="email-address"
                      />
                      <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={20} /></Text>}
                      loading={newsletter_loading}
                      style={style.go_button}
                      style_inactive={style.inactive_go_button}
                      inactive={!email_valid_newsletter}
                      onPress={this.addNewsletter} />
                  </View>
                  <View>{newsletter_added ? <Text style={style.success_text}>You've been added to our newsletter.</Text> : null}</View>
                        {/* <Text style={style.newsletter_intro_title}>Sign up for our Waitlist</Text>
                        <View style={[global_style.row, style.input_spacing]}>
                          <Input
                            style={style.input_box} 
                            type="email" value={email_waitlist}  
                            placeholder="Your email" 
                            onChangeText={event => { this.updateEmail(event, 'waitlist') }}
                            onTextInput={event => { this.updateEmail(event, 'waitlist') }}
                            onLayout={() => {}}
                            keyboardType="email-address"
                            />
                            <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={20} /></Text>}
                            loading={waitlist_loading}
                            style={style.go_button}
                            style_inactive={style.inactive_go_button}
                            inactive={!email_valid_waitlist}
                            onPress={this.signupWaitlist} />
                        </View> */}
                    </View>
            </View>
        <View style={{position: 'relative', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#1e1c1c', height: is_mobile ? 'unset' : 400, textAlign: 'center', borderTopWidth: 1, borderTopColor: '#9d9b9b', paddingVertical: is_mobile ? 50 : 'unset'}} >
          <View style={{flexDirection: 'column',  alignItems: 'center', width: '100%'}}>
            <View style={{marginBottom: 40}}><Text style={style.four_title}>Join Dining Social</Text></View>
            <View style={{flexDirection: 'row', width: is_mobile ? 'unset' : 595, justifyContent: 'center'}}>
              <TouchableOpacity onPress={()=>{track('Bottom Join Register'); window.location.replace('/register')}} ><Text style={[style.four_buttons_button, {width: 300}]}>Register for Free</Text></TouchableOpacity>
            </View>
            
          </View>
        </View>
      </View></View> : null
    );
  }
}

function DiningScreen(props) {
  if(Platform.OS === 'web') {

    let navigate = useNavigate();
    let { date, link } = useParams();
    return  <Dining_Base {...props} link={link} date={date} navigate={navigate} />
  } else {
    return  <Dining_Base {...props} navigate={props.navigation.push}  />
  }
}

export default DiningScreen;
